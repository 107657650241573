import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/session/Login/login.service';
declare var $ : any;

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './AdminSidebar.component.html',
  styleUrls: ['./AdminSidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminSidebarComponent implements OnInit {


   // sidebarIn : boolean = false;

   userType:string = "";

   modulos = {full:"Full",facturacion:"Facturacion", nomina:"Nomina",contabilidad:"Contabilidad"};
   userTypes = {administrador:"administrador",comun:"comun", editor:"editor",partner:"partner"};
   modulo:string = "";

   constructor(private loginService: LoginService){


      this.loginService.getFullUser().subscribe((data:any)=>{


         console.log("--------------------------------------------------");
         console.log(data);
         console.log("--------------------------------------------------");

         this.userType = data.TipoUsuario;
         this.modulo = data.Modulo;
      });

   }



   ngOnInit(){}

   ngAfterViewInit()
   {

   }

   toggleMenu()
   {
      if ( $('app-admin-panel').hasClass( "sidebar-in" ) ) {
         $('app-admin-panel').removeClass("sidebar-in");
      }
      else
      {
         $('app-admin-panel').addClass("sidebar-in");
      }
      // this.sidebarIn = !this.sidebarIn;
   }
}
