/*
export let API_URL = "http://localhost:57405/api/";
export let API_URL_FILES = "http://localhost:57405/Content";
export let API_URL_WEB = "http://localhost:57405/";
*/
export let API_URL = "https://pyme.logsys.mx/api/";
export let API_URL_FILES = "https://pyme.logsys.mx/Content";
export let API_URL_WEB = "https://pyme.logsys.mx/";

export let OPENPAY_PDF_SANDBOX="https://sandbox-dashboard.openpay.mx/paynet-pdf/mpo4sd1tpsim0lzfg1lv/";
export let OPENPAY_PDF_PRODUCTION="https://dashboard.openpay.mx/paynet-pdf/mcvmaxnwio0dvqa8dscl/";