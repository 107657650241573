import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type?: string;
  children?: Menu[];
}

const MENUITEMS = [
  /*
  {
    state: 'home',
    name: 'Home',
    type: 'sub',
    children: [
      {state: 'version1', name: 'Home Version 1', type: 'link'},
      {state: 'version2', name: 'Home Version 2', type: 'link'}
    ]
  },*/
  {
      state: 'productos',
      name: 'Productos',
      type: 'link'
  },
  {
    state: 'distribuidores',
    name: 'Distribuidores',
    type: 'link'
  },
  {
    state: 'timbres',
    name: 'Timbres',
    type: 'link'
  },
  {
      state: 'blog',
      name: 'Blog',
      type: 'link'
   },
   {
     state: 'contacto',
     name: 'Contacto',
     type: 'link'
   }
];


@Injectable()
export class MenuItems {
  getAll() {
    return MENUITEMS;
  }
}
