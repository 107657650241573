import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { API_URL } from '../../routes';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  
  API_URL= API_URL + "login/";

  constructor(private htttClient: HttpClient) {
    console.log("Service Login is work!!!");
  }

  login(email:string,password:string) {
    const usuario = {email: email, password: password};
    let url = this.API_URL + "authenticate"; 
    return this.htttClient.post(url,usuario);
  }

  register(nombre:string,email:string,password:string,lada:string,numero_telefono:string,tipo_usuario:string) {
    const usuario = {nombre:nombre, email: email, password: password, lada:lada, telefono:numero_telefono, tipo_usuario:tipo_usuario};
    let url = this.API_URL + "register";
    return this.htttClient.post(url,usuario);
  }


  getPing()
  {
    let url = this.API_URL + "echoping";
    return this.htttClient.get(url);
  }

  getData()
  {
    let headers = this.getHeaders();
    let url = this.API_URL + "echouser";
    return this.htttClient.get(url,headers);
  }

  getFullUser(){
    let headers = this.getHeaders();
    let url = this.API_URL + "user";
    
    return this.htttClient.get(url,headers);
  }

  getParams()
  { 
        let http = new HttpParams();
        return http;
  }
 
  recover(email:string)
  {
      let url = this.API_URL + "recover/"+email;
      return this.htttClient.get(url);
  }

  getHeaders()
  {
    let token = localStorage.getItem("token");  
    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+token,
        'Access-Control-Allow-Origin':'*' 
      })
    };

    return httpHeaders;
  }





  
}
