import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { AdminMenuItems } from './admin-menu-items';
import { LoginService } from '../../session/Login/login.service'; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-header',
  templateUrl: './AdminHeader.component.html',
  styleUrls: ['./AdminHeader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminHeaderComponent implements OnInit {


   constructor(public adminMenuItems: AdminMenuItems, private loginService: LoginService,  private router: Router){
      
   }

  userName:string = "";
  userType:string = "";
  
   Account()
   {
        this.loginService.getFullUser().subscribe(
          (data:any)=>{
            this.userName = data.Email.toString();
            this.userType = data.TipoUsuario.toString();
          },
          err => {
              localStorage.removeItem("token");            
              this.router.navigate(['/session/login']);
          });

        return false;
  }


   ngOnInit(){
     this.Account();
   }

   ngAfterViewInit()
   {
   }
}
