import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEOService } from './_services/seo.service';

@Component({
  selector: 'app-root', 
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 
  title:string = "Facturación y Nómina Electrónica CFDI 3.3";

  constructor(
    private metaTagService: Meta, private seo:SEOService
  ) { }



  ngOnInit() {
    
  }


}
