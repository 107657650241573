import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Blog } from '../site/blog/blog.interface';
import { API_URL,API_URL_FILES,API_URL_WEB } from '../routes';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor( private title: Title,    private meta: Meta,    private router: Router) {

  }

  public setTitle(t:string)
  {
    this.title.setTitle("LogSys - " + t);
  }

  public blogMetaTags(blog: Blog)
  {   

    this.meta.getTags.bind(element => {
      
    });

    this.meta.addTags([
      {name: 'fb:app_id', content:'203733810244420' },
      {name: 'og:image', content:  API_URL_FILES + '/Blog/'+ blog.Imagen },
      {name: 'og:image:secure_url', content: API_URL_FILES + '/Blog/'+ blog.Imagen},
      {name: 'og:image:type', content: 'image/jpeg'},
      {name: 'og:image:width', content: '600'},
      {name: 'og:image:height', content:'315' },
      {name: 'og:image:alt', content: blog.Titulo},
      {name: 'og:title', content: blog.Titulo},
      
      {name: 'og:type', content:'article' },
      {name: 'og:url', content:  API_URL_WEB + '/blog/'+ blog.Slug },
      {name: 'og:description', content: blog.Descripcion},
      
      {name: 'og:image:width', content: '600'},
      {name: 'og:image:height', content:'315' },
      {name: 'og:title', content: blog.Titulo},

      {name: 'twitter:site', content: '@LogSysMx'},
      {name: 'twitter:description', content: blog.Descripcion },
      {name: 'twitter:title', content: blog.Titulo},
      {name: 'twitter:image', content: API_URL_FILES + '/Blog/'+ blog.Imagen },
      {name: 'twitter:thumb', content:API_URL_FILES + '/Blog/thumb.'+ blog.Imagen },
      {name: 'thumb', content: API_URL_FILES + '/Blog/'+ blog.Imagen }
    ]);
    
  }

}
