import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.css']
})
export class RoutesComponent implements OnInit {

  API_URL="http://localhost:57405/api/";
  
  constructor() {

  }

  ngOnInit() {
    
  }

}
